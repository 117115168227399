import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import lazar from './lazar.png'

const Logo = ({ fill = "#fff" }: { fill?: string }) => {
  return (
    <LogoContainer>
      <img src={lazar} style={{ width: 50, height: 50 }}/>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }

    .Logo__Mobile{
      display: block;
    }
  `}
`;
